import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { GrFormPreviousLink } from "react-icons/gr";
import { GrFormNextLink } from "react-icons/gr";
import CategoryCard from "./shared/categoryCard";
import { useQuery } from "@tanstack/react-query";
import apis from "../services";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import reduxApis from "../redux/api";
import { useTranslation } from "react-i18next";
import { FaBoxOpen } from "react-icons/fa";

const HomeCategorySection = () => {
  const { stores } = useSelector((store) => store.store);
  const { product, loading } = useSelector((store) => store.product);
  // console.log("storeTestHomecategory", stores);
  // console.log("loadingcheck", loading);
  let { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // const {
  //   isLoading,
  //   error,
  //   data: { data: getAllProduct } = {},
  // } = useQuery({
  //   queryKey: ["getAllProductWithCategory"],
  //   queryFn: () => apis.getAllProductWithCategory(),
  //   onError: (error) => {
  //     console.error("Error fetching products:", error);
  //     toast.error("Failed to fetch products. Please try again later.");
  //   },
  // });
  const fetchData = () => {
    dispatch(
      reduxApis.getAllProductWithCategory({
        userId: user?.id,
        store_id: stores?.id,
      })
    );
  };
  useEffect(() => {
    fetchData();
  }, [stores?.[0]?.id]);

  // console.log("DATA ALL PRODUCT:", getAllProduct);

  console.log("getAllProduct", product);

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    slidesToShow: 4,
    dots: false,
    arrows: true,
    swipeToSlide: true,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // infinite: fa,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
        {
          breakpoint: 380,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
    ],
  };

  const filteredCategories = product?.data?.filter(
    (category) => category?.products?.length > 0
  );
  console.log("filteredCategories", filteredCategories);
  return (
    <>
      <section className="home-sec-2 container">
        {loading ? (
          <>
            <div className="loaderWrapper">
              <div className="loader"></div>
            </div>
          </>
        ) : (
          <>
            <div id="wrapper-category-slider">
              <div className="category-parent" id="category-parent">
                {filteredCategories?.length > 0 ? (
                  <>
                    <div className="sec2-main-heading">
                      <div className="">{t("titles.foodCategory")}</div>
                    </div>

                    {product?.data?.some(
                      (category) => category?.products?.length > 4
                    ) && (
                      <div className="category-btn-prev-next">
                        <GrFormPreviousLink
                          onClick={previous}
                          className="category-prev"
                          size={50}
                        />
                        <GrFormNextLink
                          onClick={next}
                          className="category-next"
                          size={50}
                        />
                      </div>
                    )}

                    <div className="category-sliderr" id="category-list">
                      <Slider
                        ref={(slider) => {
                          sliderRef = slider;
                        }}
                        {...settings}
                      >
                        {filteredCategories?.map((data, index) => (
                          <CategoryCard index={index} data={data} key={index} />
                        ))}
                      </Slider>
                    </div>
                  </>
                ) : (
                  <div className="no-products">
                    <FaBoxOpen className="no-products-icon" />
                    <h1>{t("titles.noProductFound")}</h1>
                    <p>{t("titles.changeStore")}</p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default HomeCategorySection;
