import React, { useEffect, useState } from "react";
import HomeScreen from "./HomeScreen";
import { StoreLocatorModal } from "../components";
import { useSelector } from "react-redux";

const Home = () => {
  const [storeLocator, setStoreLocator] = useState(false);
  const { stores } = useSelector((store) => store.store);
  console.log("STORESS LENGTH CHECK", stores);


 
  useEffect(() => {
    if (!stores || (typeof stores === 'object' && Object.keys(stores).length === 0)) {
      setStoreLocator(true);
    } else {
      setStoreLocator(false); 
    }
  }, [stores]);
  return (
    <React.Fragment>
      <HomeScreen />
      <StoreLocatorModal
        storeLocator={storeLocator}
        setStoreLocator={setStoreLocator}
      />
    </React.Fragment>
  );
};

export default Home;
